import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ComponentPreview from '../../../components/ComponentPreview';
import { SprkDivider } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Promo`}</h1>
    <p>{`Promo is a brief message and call to action
intended to draw attention to promoted or
related content.`}</p>
    <ComponentPreview componentName="promo--default-story" hasReact hasAngular hasHTML titleAttr="Promo Example" mdxType="ComponentPreview" />
    <h3>{`Usage`}</h3>
    <p>{`Use Promo to draw attention to promoted or
related content, e.g. to link a refinance
calculator in an article on the refinance process.`}</p>
    <h3>{`Guidelines`}</h3>
    <ul>
      <li parentName="ul">{`The text content of Promo should be kept concise.`}</li>
      <li parentName="ul">{`Keep the length of the title and subtitle to one line each.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Variants`}</h2>
    <h3>{`Default`}</h3>
    <p>{`The default Promo contains text and a call to action.`}</p>
    <ComponentPreview componentName="promo--default-story" hasReact hasAngular hasHTML titleAttr="Default Promo Example" mdxType="ComponentPreview" />
    <h3>{`Flag`}</h3>
    <p>{`The Flag variant displays text near a small image.
This variant is useful when you want to incorporate
an image while keeping the greatest emphasis on the text.`}</p>
    <ComponentPreview componentName="promo--flag" maxWidth="100%" hasReact hasAngular hasHTML titleAttr="Flag Promo Example" mdxType="ComponentPreview" />
    <h3>{`With Image`}</h3>
    <p>{`The Image variant includes an image that takes up
half the width of the Promo. This is useful when
you want to include an image with equal prominence to the text.`}</p>
    <p>{`To preserve the aspect ratio of Promo, the image height
will stay consistent with the height of the text content area.
If there isn’t room for the full width of the image it will be
cut off on the sides without distorting the image.`}</p>
    <ComponentPreview componentName="promo--with-image" maxWidth="100%" hasReact hasAngular hasHTML titleAttr="Promo with Image Example" mdxType="ComponentPreview" />
    <h3>{`With Image (Reversed)`}</h3>
    <p>{`Identical to the With Image variant with the image
on the opposite side.`}</p>
    <ComponentPreview componentName="promo--with-reversed-image" maxWidth="100%" hasReact hasAngular hasHTML titleAttr="Promo with Image (Reversed) Example" mdxType="ComponentPreview" />
    <h2>{`Anatomy`}</h2>
    <ul>
      <li parentName="ul">{`Promo should have a heading.`}</li>
      <li parentName="ul">{`Promo may have a sub-heading.`}</li>
      <li parentName="ul">{`Promo must have text body content.`}</li>
      <li parentName="ul">{`Promo should have a `}<a parentName="li" {...{
          "href": "/using-spark/components/button"
        }}>{`Button`}</a>{`.`}</li>
      <li parentName="ul">{`Promo may have an image.`}</li>
    </ul>
    <h2>{`Accessibility`}</h2>
    <ul>
      <li parentName="ul">{`If the Promo has an image, you must add
a descriptive `}<inlineCode parentName="li">{`alt`}</inlineCode>{` attribute for screen readers.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      